import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";

import Hero from "../components/hero";
import InfoRows from "../components/InfoRows";
import CTAColumns from "../components/cta-columns";
import CTA from "../components/cta";
import PortableText from "../components/portableText";
import Pricing from "../components/pricing";
import { TopWave, BottomWave } from "../components/wave";
import PagesList from "../components/PagesList";
import MainBanner from "../components/MainBanner";
import blogStyles from "../components/blog-post.module.css";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Figure from "../components/Figure";
import ProductDetail from "../components/ProductDetail";
import ProductList from "../components/ProductList";
import ContactInfo from "../components/ContactInfo";
import LatestPosts from "../components/LatestPosts";
import Breadcrumb from "../components/Breadcrumb";
import GGSearchSection from "../components/GGSearchSection";
import PriceTable from "../components/PriceTable";
import PhonesList from "../components/PhonesList";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      openGraph {
        title
        description
      }
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      hotlineNumber
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;
  const breadcrumbItems = page.breadcrumbItems || [];
  const structuredSEO = page.structuredSEO;

  let contentBlocks = (page._rawContent || []).filter((c) => !c.disabled);
  let content;
  content = contentBlocks.map((c, i) => {
    let el = null;
    switch (c._type) {
      case "pricing":
        el = <Pricing key={c._key} {...c} />;
        break;
      case "infoRows":
        el = <InfoRows key={c._key} {...c} />;
        break;
      case "hero":
        el = <Hero key={c._key} {...c} />;
        break;
      case "ctaColumns":
        el = <CTAColumns key={c._key} {...c} />;
        break;
      case "ctaPlug":
        el = <CTA key={c._key} {...c} />;
        break;
      case "productDetail":
        el = <ProductDetail key={c._key} {...c} {...site} />;
        break;
      case "block":
        el = (
          <div
            key={c._key}
            className={classNames("container mx-auto px-4 lg:px-0 my-2", blogStyles.mainContent)}
          >
            <PortableText blocks={c} />
          </div>
        );
        break;
      case "productList":
        el = <ProductList key={c._key} {...c} />;
        break;
      case "contentImage":
        el = (
          <div key={c._key} className={classNames("container mx-auto", blogStyles.mainContent)}>
            <PortableText blocks={c} />
          </div>
        );
        break;
      case "mainBanner":
        el = <MainBanner key={c._key} {...c} />;
        break;
      case "priceTable":
        el = <PriceTable key={c._key} {...c} />;
        break;
      case "uiComponentRef":
        switch (c.name) {
          case "phonesList":
            el = <PhonesList key={c._key} />;
            break;
          case "topWave":
            el = <TopWave key={c._key} />;
            break;
          case "ggSearch":
            el = <GGSearchSection key={c._key} />;
            break;
          case "bottomWave":
            el = <BottomWave key={c._key} />;
            break;
          case "pagesList":
            el = <PagesList key={c._key} />;
            break;
          case "contactForm":
            el = <ContactInfo key={c._key} />;
            break;
          case "latestBlogPosts":
            el = <LatestPosts key={c._key} />;
            break;
          default:
            break;
        }
        break;
      default:
        console.log({ type: c._type, c });
        el = null;
    }
    return el;
  });
  // if (contentBlocks.length && contentBlocks[0]._type === "block") {
  //   // This is a block content, return portable text instead
  //   content = (
  //     <div className={classNames("container pt-4 px-2 pb-12 mx-auto", blogStyles.mainContent)}>
  //       <PortableText blocks={page._rawContent} />
  //     </div>
  //   );
  // } else {

  // }

  const gradient = {
    from: (site.primaryColor && site.primaryColor.hex) || "#d53369",
    to: (site.secondaryColor && site.secondaryColor.hex) || "#daae51",
  };

  let menuItems = page.navMenu && (page.navMenu.items || []);
  if (menuItems?.length) {
    // Merge menu items with raw
    menuItems = menuItems.map((item, i) => {
      const rawItem = page.navMenu._rawItems[i];
      return {
        ...item,
        ...rawItem,
      };
    });
  }
  const pageTitle =
    data.route && !data.route.useSiteTitle && data.route.openGraph
      ? data.route.openGraph.title
      : page.title || site.title;
  const pageDescription =
    data.route && !data.route.useSiteTitle && data.route.openGraph
      ? data.route.openGraph.description
      : site.openGraph?.description;
  const pagePath = data.route?.slug?.current || "/";

  return (
    <Layout navMenuItems={menuItems} textWhite={true} hotlineNumber={site.hotlineNumber}>
      <SEO
        title={pageTitle}
        description={pageDescription}
        keywords={site.keywords}
        bodyAttr={{
          class: "leading-normal tracking-normal",
        }}
        lang="vi"
        url={`${site.baseUrl || "https://capdiensaigon.com"}${pagePath}`}
        gradient={gradient}
      />
      {Boolean(breadcrumbItems.length) && (
        <Breadcrumb items={breadcrumbItems} currentTitle={pageTitle} />
      )}
      {structuredSEO?.jsonString && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: structuredSEO?.jsonString || '' }}
        />
      )}
      <div className={breadcrumbItems.length ? "" : "pt-24"}>{content}</div>
      <div id="fb-root"></div>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=742368589893052&autoLogAppEvents=1"
        nonce="R5896cZU"
      ></script>
    </Layout>
  );
};

export default Page;
