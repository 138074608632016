import React, { useState } from "react";

import PortableText from "./portableText";
import MessengerIcon from "./icon/messenger";
import PhoneIcon from "./icon/phone";

const ProductDetail = (props) => {
  const imageSrc = props.rows.illustration.image?.asset?.url || "https://dummyimage.com/720x600";
  const hotline = props.hotlineNumber || "";
  return (
    <section className="text-gray-700 body-font">
      <div className="container mx-auto flex items-center px-5 py-8 md:flex-row flex-col">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img className="object-cover object-center rounded" alt="hero" src={imageSrc} />
        </div>

        <div className="lg:flex-grow md:w-1/2 lg:pl-12 md:pl-8 my-2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-900">
            {props.name}
          </h1>
          <div className="mb-4 leading-relaxed">
            <PortableText blocks={props.description} />
          </div>

          <div className="container mx-auto flex flex-wrap w-full justify-center lg:justify-around my-2 lg:my-4">
            <a
              href={`https://zalo.me/${hotline.split(" ").join("")}`}
              target="_blank"
              rel="noreferrer nofollow"
              className="inline-flex w-full justify-center xl:w-auto items-center text-white bg-blue-500 border-0 my-2 py-2 px-6 lg:px-12 focus:outline-none hover:bg-blue-600 rounded text-lg"
            >
              <MessengerIcon className="mr-2" />
              Zalo
            </a>

            <a
              href={`tel:${hotline.split(" ").join("")}`}
              className="inline-flex w-full justify-center xl:w-auto items-center text-white bg-yellow-600 border-0 my-2 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg"
              title={`Gọi tới số Hotline: ${hotline}`}
            >
              <PhoneIcon className="mr-2" />
              {hotline}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

ProductDetail.propTypes = {};

export default ProductDetail;
