import { Link } from "gatsby";
import React, { useState, useCallback } from "react";
import NavMenuItem from "./NavMenuItem";
import NavMenuGroupItem from "./NavMenuGroupItem";
import CTALink from "./CTALink";
import HamburgerIcon from "./icon/hamburger";

const Header = ({
  showNav,
  siteTitle,
  hotlineNumber,
  scrolled,
  navMenuItems = [],
  textWhite = true,
}) => {
  let headerClass = "fixed w-full max-h-full overflow-scroll lg:overflow-visible z-30 top-0";
  headerClass += scrolled ? " bg-white shadow" : " bg-white";
  const [show, setShow] = useState(false);
  const handleToggleNav = useCallback(() => {
    setShow(prev => !prev);
  }, []);
  let navActionClass =
    "mx-auto lg:mx-0 hover:underline rounded-full mt-4 lg:mt-0 py-2 px-4 shadow opacity-75";
  navActionClass += !textWhite || !scrolled ? " bg-white text-gray-800" : "";
  navActionClass += textWhite || scrolled ? "" : "";

  let navContentClass =
    "w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 p-4 lg:p-0 z-20";
  if (!show) {
    navContentClass += " hidden";
  }
  navContentClass += !textWhite || !scrolled ? " lg:bg-transparent bg-gray-100" : "";
  navContentClass += textWhite || scrolled ? " bg-white" : "";

  let titleClass =
    "toggleColour no-underline text-yellow-600 hover:no-underline font-semibold text-lg lg:text-xl";
  titleClass += !textWhite || scrolled ? " text-gray-800" : "";

  const hotline = hotlineNumber || "";
  return (
    <nav id="header" className={headerClass}>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link id="siteTitle" className={titleClass} to="/">
            <img className="inline-block" src="/logo-new.png" width={48} alt="Cáp điện Sài Gòn" />{" "}
            {siteTitle}
          </Link>
        </div>

        <button
          onClick={handleToggleNav}
          aria-label="Menu Toggle"
          className="text-yellow-700 mr-2 p-2 border rounded-sm hover:text-white hover:bg-yellow-600 text-2xl lg:hidden"
        >
          <HamburgerIcon />
        </button>

        {showNav && navMenuItems && (
          <div className={navContentClass} id="nav-content">
            <ul className="list-reset lg:flex justify-end flex-1 items-center">
              {navMenuItems.map((item, idx) => {
                if (item._type === "ctaGroup" && item.items) {
                  return <NavMenuGroupItem key={item._key} {...item} />;
                }
                return <NavMenuItem key={item._key} {...item} buttonActionClass={navActionClass} />;
              })}

              <li key={"search"} className="mr-2 text-yellow-800 hover:text-yellow-600 uppercase">
                <CTALink
                  kind="button"
                  route="/tim-kiem"
                  title={
                    <svg
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="xMidYMid"
                      width="1em"
                      height="1em"
                    >
                      <circle
                        cx="10.5"
                        cy="10.5"
                        r="6"
                        stroke="currentColor"
                        style={{
                          vectorEffect: "non-scaling-stroke",
                        }}
                      ></circle>
                      <line
                        x1="14.6036"
                        y1="14.6464"
                        x2="20.6069"
                        y2="20.6498"
                        stroke="currentColor"
                        style={{
                          vectorEffect: "non-scaling-stroke",
                        }}
                      ></line>
                    </svg>
                  }
                  buttonActionClass={navActionClass}
                />
              </li>

              <li key={"call_me"} className="mr-3 text-yellow-800 hover:text-yellow-600 uppercase">
                <a
                  className={
                    "mx-auto lg:mx-0 hover:underline rounded-full mt-4 lg:mt-0 py-2 px-4 shadow text-white bg-yellow-600 blink"
                  }
                  href={`tel:` + hotline.split(" ").join("")}
                  title={`Gọi tới số Hotline: ${hotline}`}
                >
                  {hotline}
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>

      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  );
};

export default Header;
