import React from "react";
import PortableText from "./portableText";
import classNames from "classnames";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

const maybeImage = (illustration, doubled = false) => {
  let img = null;
  let href = "#";
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: doubled ? 496 : 240 },
      clientConfig.sanity
    );

    img = <img className="w-full mx-auto" src={fluidProps.src} alt={illustration.image.alt} />;
    href = illustration.image.caption;
  }
  return { img, href };
};

const InfoRow = (props) => {
  const { img, href } = maybeImage(props.illustration, props.isTwoCols);
  return (
    <div
      className={classNames(
        "flex flex-col p-2",
        props.isTwoCols ? "w-full xl:w-1/2" : "w-1/2 xl:w-1/4"
      )}
    >
      {img && <div className={"w-full mb-4"}>{img}</div>}
      <h3 className="text-xl text-center uppercase leading-none mb-3">
        <a className="text-yellow-700 hover:text-indigo-600" href={href} title={props.title}>
          {props.title}
        </a>
      </h3>
    </div>
  );
};

const InfoRowFlipped = (props) => {
  const img = maybeImage(props.illustration);
  const sizeClass = img ? "sm:w-1/2" : "sm:w-1/1";
  return (
    <div className={"flex flex-wrap pb-6 flex-col-reverse sm:flex-row"}>
      {img && <div className={"w-full " + sizeClass}>{img}</div>}
      <div className={"w-5/6 p-6 " + sizeClass}>
        <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">{props.title}</h3>
        <div className="text-gray-600 mb-8">
          <PortableText blocks={props.text} />
        </div>
      </div>
    </div>
  );
};

const InfoRows = (props) => {
  const contentItems = (props.rows || []).filter((r) => !r.disabled);
  const isTwoCols = contentItems.length <= 2;
  const contentRows = contentItems.map((r, i) => {
    return <InfoRow isTwoCols={isTwoCols} key={r._key} {...r} />;
  });

  return (
    <section className="bg-white py-8">
      <div className="container max-w-5xl mx-auto m-8">
        <h1 className="w-full my-2 text-2xl xl:text-4xl font-bold leading-tight text-center text-yellow-700">
          {props.title}
        </h1>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div className="flex flex-wrap">{contentRows}</div>
      </div>
    </section>
  );
};

export default InfoRows;
