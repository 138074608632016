import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

const PriceTable = ({ title, table }) => {
  const [isOpen, setOpen] = useState(true);
  const togglePriceTable = useCallback(() => setOpen((prev) => !prev), []);
  let tableElemennt;
  if (table?.rows?.length) {
    const thRow = table.rows.length > 1 ? table.rows[0] : null;
    const tbRows = table.rows.length > 1 ? table.rows.slice(1) : table.rows;

    tableElemennt = (
      <table className="min-w-full bg-white shadow table-auto">
        {thRow && (
          <thead className="bg-gray-100">
            <tr key={thRow._key}>
              {thRow.cells.map((content, idx) => {
                return (
                  <th
                    key={idx}
                    className={
                      "text-left p-3 uppercase font-semibold text-sm border whitespace-no-wrap"
                    }
                  >
                    {content}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}

        <tbody>
          {tbRows.map(({ _key, cells }) => {
            return (
              <tr key={_key} className="hover:bg-gray-100">
                {cells.map((cell, idx) => {
                  return (
                    <td key={idx} className="text-left p-3 border">
                      {cell}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <div className="mx-auto my-4">
      <h3 className="title-font sm:text-2xl text-xl text-center font-medium text-yellow-700 uppercase mb-4">
        {title}
      </h3>

      <div className="flex justify-center align-center">
        <button
          className="text-white font-bold rounded-full my-6 py-4 px-8 bg-yellow-600 shadow-lg cursor-pointer"
          onClick={togglePriceTable}
        >
          {isOpen ? "Đóng" : "Mở"} bảng giá
        </button>
      </div>

      <div className="container overflow-x-auto mx-auto mb-4">{isOpen && tableElemennt}</div>
    </div>
  );
};

PriceTable.propTypes = {};

export default PriceTable;
